.rhap_container {
    max-height: 90px;
    min-height: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: inherit;
    width: 100%;
    padding: 10px 40px 0 40px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    /*// box-shadow: 0 0 3px #403f3f;*/
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    background-color: rgba(0,0,0,.75);
    z-index: 103;
    transition: background-color .35s cubic-bezier(.43,-.1,.16,1.1);
    outline-style: none
}
@media only screen and (max-width: 599px) {
    .rhap_container {
        padding: 0 10px 0 10px;
    }
}
.rhap_container:focus:not(:focus-visible) {
    outline: 0;
}
.rhap_container svg {
    vertical-align: initial;
}

.rhap_header {
    margin-bottom: 10px;
}

.rhap_footer {
    margin-top: 5px;
}

.rhap_main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
    margin-top: 8px;
}

.rhap_horizontal {
    flex-direction: row;
}
.rhap_horizontal .rhap_controls-section {
    margin-left: 8px;
}

.rhap_horizontal-reverse {
    flex-direction: row-reverse;
}
.rhap_horizontal-reverse .rhap_controls-section {
    margin-right: 8px;
}

.rhap_stacked-reverse {
    flex-direction: column-reverse;
}
@media only screen and (max-width: 599px) {
    .rhap_stacked-reverse .rhap_controls-section {
        align-items: end;
    }
}
@media only screen and (max-width: 599px) {
    .rhap_controls-section {
        display: block !important;
    }
}

.rhap_progress-section {
    display: flex;
    flex: 3 1 auto;
    align-items: center;
}
@media only screen and (max-width: 599px) {
    .rhap_progress-section {
        flex: unset;
    }
}

.rhap_progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1 0 auto;
    align-self: center;
    margin: 0 calc(10px + 1%);
    cursor: pointer;
    -webkit-user-select: none;
}
@media only screen and (max-width: 599px) {
    .rhap_progress-container{
        margin: unset;
        align-items: unset;
        height: unset;
        align-self: unset;
    }
}
.rhap_progress-container:focus:not(:focus-visible) {
    outline: 0;
}

.rhap_time {
    color: gray;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
}
@media only screen and (max-width: 599px) {
    .rhap_time {
        display: inline-block;
    }
    .rhap_current-left-time {
        position: absolute;
        right: 0;
    }
}

.rhap_progress-bar {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 5px;
    background-color: #dddddd;
    border-radius: 2px;
}
@media only screen and (max-width: 599px) {
    .rhap_progress-bar{
        height: 2px;
    }
}

.rhap_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #0a16fc;
    border-radius: 2px;
}

.rhap_progress-bar-show-download {
    background-color: rgba(221, 221, 221, 0.5);
}

.rhap_download-progress {
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: #dddddd;
    border-radius: 2px;
}

.rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    top: -8px;
    background: #3b44fc;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}
@media only screen and (max-width: 599px) {
    .rhap_progress-indicator {
        display: none;
    }
}

.rhap_controls-section {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    position: relative;
}

.rhap_additional-controls {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
}
.rhap_additional-controls:hover {
    opacity: 0.9;
    color: #3b44fc;
}
.rhap_repeat-button {
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: #868686;
    margin-right: 6px;
}

.rhap_main-controls {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
/*@media only screen and (max-width: 426px) {*/
/*    .rhap_main-controls {*/
/*        position: absolute;*/
/*        right: 0;*/
/*    }*/
/*}*/
.rhap_main-controls-button {
    margin: 0 3px;
    color: #FFF;
    font-size: 35px;
    width: 35px;
    height: 35px;
}

.rhap_play-pause-button {
    font-size: 40px;
    width: 40px;
    height: 40px;
}

.rhap_volume-controls {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;
}

.rhap_volume-button {
    flex: 0 0 26px;
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: #FFF;
    margin-right: 6px;
}

.rhap_volume-container {
    display: flex;
    align-items: center;
    flex: 0 1 100px;
    -webkit-user-select: none;
}

.rhap_volume-bar-area {
    display: flex;
    align-items: center;
    width: 100%;
    height: 14px;
    cursor: pointer;
}
.rhap_volume-bar-area:focus:not(:focus-visible) {
    outline: 0;
}

.rhap_volume-bar {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 4px;
    background: gray;
    border-radius: 2px;
}

.rhap_volume-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    margin-left: -6px;
    left: 0;
    top: -4px;
    background: #3b44fc;
    opacity: 0.9;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
    cursor: pointer;
}
.rhap_volume-indicator:hover {
    opacity: 0.9;
}

/* Utils */
.rhap_button-clear {
    background-color: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
}

@media only screen and (max-width: 599px) {
    .rhap_button-clear {
        height: 60px;
        width: 60px;
    }
}

.rhap_button-clear:hover {
    opacity: 0.9;
    color: #3b44fc;
    /*transition-duration: 0.2s;*/
}
.rhap_button-clear:active {
    opacity: 0.95;
}
.rhap_button-clear:focus:not(:focus-visible) {
    outline: 0;
}

.MuiMenu-paper ::-webkit-scrollbar {
    width: 8px;
    background-color: #f7f8fa;
}

 .MuiMenu-paper ::-webkit-scrollbar-thumb {
     height: 20px;
     background-color: #3b44fc;
     opacity: .5;
 }