
p {
  margin: 0;
}
a:visited {
  color: #3b44fc
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.swiper-container {
  margin-left: 0 !important;
  margin-right: 0 !important
}
.swiper-slide {
  /*width: initial !important;*/
  height: unset !important;
  padding: 0 3px
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.snackBg{
  background: #0a16fc !important;
}
.anyplayText {
  opacity: 0.9;
}

.flexCenter {
  display: flex;
  align-items: center;
}
.infinite-scroll-component{
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
.infinite-scroll-component__outerdiv {
  width: 100%
}
@media only screen and (min-width: 600px) and (max-width: 760px) {
  .actionBlock {
    display: block !important;
    text-align: center;
    margin: 0 auto;
  }
}


@font-face {
  font-family: 'Graphik';
  src: url('./assets/font/Graphik-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('./assets/font/Graphik-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('./assets/font/Graphik-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('./assets/font/Graphik-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('./assets/font/Graphik-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

.greyText {
  opacity: 0.7;
}


.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}
.my-masonry-grid_column {

  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 25px;
}

.grayText {
  opacity: 0.7
}