body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*input:-webkit-autofill,*/
/*input:-webkit-autofill:hover,*/
/*input:-webkit-autofill:focus,*/
/*input:-webkit-autofill:active  {*/
/*  -webkit-box-shadow: 0 0 0 30px white inset !important;*/
/*}*/
input:-webkit-autofill, input:focus:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #3e3e3e inset !important;
  -webkit-text-fill-color: white!important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.w100 {
  width: 100%;
}
.noTd {
  text-decoration: none
}
.cardImg {
  width: 230px;
  height: auto;

}
.marginCenter {
  margin: 0 auto !important;
}
.marginCenterBlock {
  margin: 0 auto !important;
  display: block !important;
}
.textCenter {
  text-align: center;
}
.cardImgPlaylistGroup {

  /*min-height: 105px;*/
  width: 100%;
}
.cardHeader{
  /* padding-top: 2rem;*/
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
}
.cardHeaderWrap{
  display: flex;
  align-items: center;
}
.cardHeaderAll {
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  color: gray !important;
  white-space: nowrap;
}
input,
.StripeElement {
  display: block;
  margin: 10px 0 0 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0 1px 3px,
  rgba(0, 0, 0, 0.0196078) 0 1px 0;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0 4px 6px,
  rgba(0, 0, 0, 0.0784314) 0 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.boldMenu {
  font-weight: 600 !important;
}
* ::-webkit-scrollbar {
  width: 8px;
  background-color: #f7f8fa;
}

*  ::-webkit-scrollbar-thumb {
  height: 20px;
  background-color: #3b44fc;
  opacity: .5;
}

.MuiGrid-root ::-webkit-scrollbar {
  width: 8px;
  background-color: #f7f8fa;
}

.MuiGrid-root ::-webkit-scrollbar-thumb {
  height: 20px;
  background-color: #3b44fc;
  opacity: .5;
}

@media only screen and (max-width: 768px) {
  * ::-webkit-scrollbar {
    width: 0;
    background-color: #f7f8fa;
  }
  *  ::-webkit-scrollbar-thumb {
    height: 0;
    background-color: #3b44fc;
    opacity: .5;
  }
  .MuiGrid-root ::-webkit-scrollbar {
    width: 0;
    background-color: #f7f8fa;
  }

  .MuiGrid-root ::-webkit-scrollbar-thumb {
    height: 0;
    background-color: #3b44fc;
    opacity: .5;
  }
}